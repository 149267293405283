
.mat-grid-tile {
  &.start {
    .mat-grid-tile-content {
      justify-content: flex-start;
    }
  }
  &.end {
    .mat-grid-tile-content {
      justify-content: flex-end;
    }
  }
}

.mat-card {
  box-shadow:0 1px 4px rgb(21 34 50 / 8%) !important;
  margin-bottom: 30px;
}

.mat-dialog-container {
  max-height: 100vh !important;
  overflow: auto !important;
  background-color: #fafbfc;
}

/* bottom empty space */
.mat-form-field-wrapper{
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.mat-list-text {
  padding-right: 5px !important;
}