.d_f {
  display: flex;
}

.d_n {
  display: none;
}

.j-c_s-b {
  display: flex;
  justify-content: space-between;
}

.a-i_c {
  display: flex;
  align-items: center;
}

.j-c_f-e {
  display: flex;
  justify-content: flex-end;
}

.m-b_0 {
  margin-bottom: 0 !important;
}

.m-b_5 {
  margin-bottom: 5px;
}

.m-b_10 {
  margin-bottom: 10px;
}

.m-b_20 {
  margin-bottom: 20px;
}

.m-b_30 {
  margin-bottom: 30px;
}

.m-b_40 {
  margin-bottom: 40px;
}

.m-b_50 {
  margin-bottom: 50px;
}

.w_100 {
  width: 100%;
}

.t-a_c {
  text-align: center;
}

.t-t_u {
  text-transform: uppercase;
}

.t-a_r {
  text-align: right;
}

.t-a_l {
  text-align: left;
}

.t-a_li {
  text-align: left !important;
}

.t-a_ri {
  text-align: right !important;
}

.c-green {
  color: green;
}

body {
  margin: 0;
  -webkit-print-color-adjust: exact;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  background-color: #fff;
  color: #000;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

*[class^=items] {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

.mat-drawer-container {
  background-color: #fafbfc;
}

.mat-grid-tile.start .mat-grid-tile-content {
  justify-content: flex-start;
}
.mat-grid-tile.end .mat-grid-tile-content {
  justify-content: flex-end;
}

.mat-card {
  box-shadow: 0 1px 4px rgba(21, 34, 50, 0.08) !important;
  margin-bottom: 30px;
}

.mat-dialog-container {
  max-height: 100vh !important;
  overflow: auto !important;
  background-color: #fafbfc;
}

/* bottom empty space */
.mat-form-field-wrapper {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.mat-list-text {
  padding-right: 5px !important;
}

.modal {
  padding: 0 0 20px;
}
.modal .mat-dialog-actions {
  display: flex;
  justify-content: flex-end;
}
.modal .mat-dialog-content {
  white-space: break-spaces;
}

.arm-form {
  padding: 15px 20px 0 !important;
  margin-bottom: 40px;
}
.arm-form .action-button {
  height: 51px;
  position: relative;
  top: 5px;
}
.arm-form .action-button mat-icon {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

/* .mat-form-field {
  max-width: 100%;
  width: 100%;
} */
.mat-table {
  width: 100%;
}

.mat-sort-header-container {
  justify-content: center !important;
}

/*
.table-list {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  font-size: 16px;
  overflow: hidden;
  > li {
    display: grid;
    grid-column-gap: 20px;
    min-height: 40px;
    align-items: center;
    transition: all .2s;
    &.table-title {
      color: #263238;
      font-weight: 500;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      > *:last-child {
        padding-right: 10px;
      }
    }
    &.disabled {
      cursor: not-allowed !important;
    }
    > *:first-child {
      padding-left: 10px;
    }
    > *:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &:nth-child(odd) {
      background-color: #F4F4F4;
    }
  }
}
*/
.players-items .mat-checkbox-label {
  line-height: 1.2;
}

.sidenav-list .mat-list-item .text-el {
  margin-left: 16px;
}
.sidenav-list .mat-list-item.active {
  font-weight: bolder;
  color: #3f51b5;
}
.sidenav-list .mat-list-item.active svg {
  fill: #3f51b5;
}

.mat-snack-bar-container {
  color: #fff;
  white-space: pre-wrap;
}
.mat-snack-bar-container.error {
  background-color: red;
}
.mat-snack-bar-container.success {
  background-color: green;
}
.mat-snack-bar-container.message {
  background-color: blue;
}
.mat-snack-bar-container .mat-simple-snackbar {
  flex-direction: column;
  align-items: center;
}
.mat-snack-bar-container .mat-simple-snackbar-action {
  color: #fff;
  padding-top: 15px;
}