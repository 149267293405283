.mat-table {
  width: 100%;
}

.mat-sort-header-container {
  justify-content: center !important;
}

/*
.table-list {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  font-size: 16px;
  overflow: hidden;
  > li {
    display: grid;
    grid-column-gap: 20px;
    min-height: 40px;
    align-items: center;
    transition: all .2s;
    &.table-title {
      color: #263238;
      font-weight: 500;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      > *:last-child {
        padding-right: 10px;
      }
    }
    &.disabled {
      cursor: not-allowed !important;
    }
    > *:first-child {
      padding-left: 10px;
    }
    > *:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &:nth-child(odd) {
      background-color: #F4F4F4;
    }
  }
}
*/
