.modal {
  padding: 0 0 20px;
  .mat-dialog-actions {
    display: flex;
    justify-content: flex-end;
  }
  .mat-dialog-content {
    white-space: break-spaces;
  }
}


.arm-form {
  padding: 15px 20px 0 !important;
  margin-bottom: 40px;
  .action-button {
    height: 51px;
    position: relative;
    top: 5px;
    mat-icon {
      font-size: 40px;
      width: 40px;
      height: 40px;
    }
  }
}



/* .mat-form-field {
  max-width: 100%;
  width: 100%;
} */

