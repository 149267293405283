.d_f {
  display: flex;
}

.d_n {
  display: none;
}

.j-c_s-b {
  display: flex;
  justify-content: space-between;
}

.a-i_c {
  display: flex;
  align-items: center;
}

.j-c_f-e {
  display: flex;
  justify-content: flex-end;
}

.m-b_0 {
  margin-bottom: 0 !important;
}

.m-b_5 {
  margin-bottom: 5px;
}

.m-b_10 {
  margin-bottom: 10px;
}

.m-b_20 {
  margin-bottom: 20px;
}

.m-b_30 {
  margin-bottom: 30px;
}

.m-b_40 {
  margin-bottom: 40px;
}

.m-b_50 {
  margin-bottom: 50px;
}

.w_100 {
  width: 100%;
}

.t-a_c {
  text-align: center;
}

.t-t_u {
  text-transform: uppercase;
}

.t-a_r {
  text-align: right;
}

.t-a_l {
  text-align: left;
}

.t-a_li {
  text-align: left !important;
}

.t-a_ri {
  text-align: right !important;
}

.c-green {
  color: green;
}

%title-card {
  font-size: 15px;
  text-transform: uppercase;
}
