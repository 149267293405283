.sidenav-list {
    .mat-list-item{
      .text-el {
        margin-left: 16px;
      }
      &.active {
        font-weight: bolder;
        color: #3f51b5;
        svg {
          fill: #3f51b5;
        }
      }
    }
  }