body {
  margin: 0;
  -webkit-print-color-adjust: exact;
}

html,
body {
  min-height: 100%;
  height: 100%;
}
body {
  background-color: #fff;
  color: #000;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

*[class^='items'] {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

.mat-drawer-container {
  background-color: #fafbfc;
}

