.mat-snack-bar-container {
  color: #fff;
  white-space: pre-wrap;
  &.error {
    background-color: red;
  }
  &.success {
    background-color: green;
  }
  &.message {
    background-color: blue;
  }
  .mat-simple-snackbar {
    flex-direction: column;
    align-items: center;
  }
  .mat-simple-snackbar-action {
    color: #fff;
    padding-top: 15px;
  }
}
